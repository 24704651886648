.wrapper {
    padding: 40px 16px 8px;
    position: relative;
    background: linear-gradient(180deg, rgb(184 250 202) 0%, rgb(197 231 204) 88%, rgb(222 247 225) 100%);
}

.container {
    max-width: 400px;
    margin: 0 auto;
}

.goals {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 32px;
}

.goals__item {
    padding: 4px 12px;
    background: var(--surface-default);
    border-radius: 0 10px;
}

.parameters {
    position: relative;
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
}

.parameters__item {
    position: relative;
    z-index: 2;
    flex: 1 1 0;
    min-width: 96px;
    max-width: 132px;
    max-height: 82px;
    padding: 10px 19px;
    background: var(--surface-default);
    border-radius: 0 10px;
}

.parameters__item p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.parameters__item:after {
    content: '';
    position: absolute;
    bottom: -74px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 73px;
}

.parameters__item--level:after {
    border-left: 1px dashed var(--decorative-b);
    border-bottom: 1px dashed var(--decorative-b);
    border-bottom-left-radius: 10px;
}

.parameters__item--type:after {
    bottom: -11px;
    width: 0;
    height: 10px;
    border-left: 1px dashed var(--decorative-b);
}

.parameters__item--age:after {
    border-right: 1px dashed var(--decorative-b);
    border-bottom: 1px dashed var(--decorative-b);
    border-bottom-right-radius: 10px;
}

.imageWrapper {
    position: relative;
    z-index: 2;
}

.image {
    margin: 0 auto;
}

.imageWrapper:after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 10px;
    border-left: 1px dashed var(--decorative-b);
}

.imageWrapper:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid var(--decorative-b);
    clear: both;
}
